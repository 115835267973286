
/* eslint-disable no-return-assign */
import {rzPreloader, rzTabs, rzContainer} from 'razlet-ui';
import PopularDirections from '~/components/popular-directions';
import { removeGlobalEvent } from '~/utils/event-handler';
import globalEvents from '~/mixins/global-events';

export default {
  name: 'index',
  transition: 'fade',
  components: {
    rzPreloader,
    rzTabs,
    PopularDirections,
    rzContainer,
  },
  mixins: [globalEvents],
  data() {
    return {
      isAffiliatorLoaded: false,
      isAffiliatorReady: false,
      isSlotMounted: false,
      direction: '',
      tabs: [{value: 'form'}, {value: 'check'}, {value: 'refund'} /* , {value: 'international'} */],
    };
  },
  computed: {
    language() {
      return this.$local ? this.$local : 'ru';
    },
  },
  /* watch: {
    isSlotMounted: {
      handler(val) {
        if (val) this.addPopularDirections();
      },
    },
  }, */
  mounted() {
    window.customElements.whenDefined('razlet-affiliator').then(() => {
      this.isAffiliatorLoaded = true;
    });
    document.getElementsByClassName('application')[0].style = 'background-color: white !important';
  },
  beforeDestoy() {
    document.getElementsByClassName('application')[0].style.removeProperty('background-color');
    removeGlobalEvent('home-mounted');
    removeGlobalEvent('home-destroyed');
  },
  methods: {
    ready() {
      this.isAffiliatorReady = true;
      this.isSlotMounted = true;
    },
    selectDirection(direction) {
      this.direction = `${direction.from.code}0000${direction.to.code}1000es`;
    },
    addPopularDirections() {
      const parentEl = document.getElementById('homeSlot');
      if (parentEl) {
        const directionsEl = this.$refs.popularDirections.$el;
        directionsEl.className = 'popular-directions';
        parentEl.appendChild(directionsEl);
        parentEl.className = 'visible';
      }
    },
  },
  head() {
    return {
      link: [{ rel: 'preload', as: 'image', href: `/bg.png` }],
    };
  },
};
