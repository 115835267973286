import { render, staticRenderFns } from "./index.vue?vue&type=template&id=78735f1e"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=78735f1e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsAirplane: require('/opt/catfly.ru/src/components/icons/airplane.vue').default,IconsCheck: require('/opt/catfly.ru/src/components/icons/check.vue').default,IconsReturn: require('/opt/catfly.ru/src/components/icons/return.vue').default,IconsCurrency: require('/opt/catfly.ru/src/components/icons/currency.vue').default,PopularDirections: require('/opt/catfly.ru/src/components/popular-directions.vue').default,ReturnBlock: require('/opt/catfly.ru/src/components/return-block.vue').default,SupportBlock: require('/opt/catfly.ru/src/components/support-block.vue').default,FaqBlock: require('/opt/catfly.ru/src/components/faq-block.vue').default})
