
import {rzIconCheck} from 'razlet-ui';
import supportCat from '~/assets/img/support-cat.webp';

export default {
  name: 'support-block',
  components: {
    rzIconCheck,
  },
  data() {
    return {
      supportCat,
      list: [
        'Лучшие цены на авиабилеты',
        'Быстрый онлайн обмен или возврат билета',
        'Оплата иностранными картами',
        'Обслуживание корпоративных клиентов',
      ],
    };
  },
};
