
import {rzButton, rzIconArrowThin} from 'razlet-ui';

export default {
  name: 'popular-directions',
  components: {
    rzButton,
    rzIconArrowThin,
  },
  data() {
    return {
      selectedPage: 0,
      directions: [
        { 
          from: {code: 'KJA', name: 'Красноярск'}, 
          to: {code: 'OSS', name: 'Ош'},
          price: '10 000',
          color: '#4D6F92',
        },
        { 
          from: {code: 'KJA', name: 'Красноярск'},  
          to: {code: 'FRU', name: 'Бишкек'},
          price: '10 000',
          color: '#8797B6',
        },
        { 
          from: {code: 'MOW', name: 'Москва'},  
          to: {code: 'FRU', name: 'Бишкек'},
          price: '10 000',
          color: '#D3BDBF',
        },
        { 
          from: {code: 'MOW', name: 'Москва'}, 
          to: {code: 'OSS', name: 'Ош'},
          price: '10 000',
          color: '#FAB199',
        },
      ],
    };
  },
  computed: {
    pagesLength() {
      return this.directions.length * 0.5;
    },
  },
  mounted() { 
    const directionsButtons = this.$refs.buttons;
    directionsButtons.addEventListener('scroll', () => {
      const scrollRelation = directionsButtons.scrollLeft / (directionsButtons.scrollWidth - directionsButtons.clientWidth);
      directionsButtons.addEventListener('touchend', () => {
        this.scrollSet(scrollRelation);
      });
    });
  },
  beforeDestoy() {
    window.removeEventListener('scroll', this.$refs.buttons);
  },
  methods: {
    goToDirection(direction) {
      this.$emit('select', direction);
      // this.$router.replace({ name: 'index', query: {direction: JSON.stringify(direction)} });
    },
    changePage(ind) {
      this.selectedPage = ind;
      this.$refs.buttons.scrollTo({
        left: ind === 0 ? 0 : (this.$refs.buttons.scrollWidth / this.pagesLength + 10),
        behavior: 'smooth',
      });
    },
    scrollSet(scrollRelation) {
      if (scrollRelation > 0 && scrollRelation < 0.25) {
        this.changePage(1);
      } else if (scrollRelation > 0.75 && scrollRelation < 1) {
        this.changePage(0);
      }
    },
  },
};
