
export default {
  name: 'airplane-icon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 23,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
