
import {rzIconDropArrow, rzTransitionExpand} from 'razlet-ui';
import faqCat from '~/assets/img/faq-cat.webp';
import {sendGlobalEvent} from '~/utils/event-handler';

export default {
  name: 'faq-block',
  components: {
    rzIconDropArrow,
    rzTransitionExpand,
  },
  data() {
    return {
      faqCat,
      list: [
        {
          title: 'Мне не пришел билет. Что делать?',
          description: `Маршрутная квитанция (электронный билет) поступает в течение 3 часов на почту, которую вы указали при бронировании.
          Если на почте письма нет в основной папке «Входящие», проверьте папку «Спам» или «Рассылки». Если в «Спаме» ничего нет и уже прошло более 3-х часов с момента покупки, возможно, адрес эл.почты был указан некорректно. Чтобы получить билет, обратитесь к нам в Контактный центр. Мы направим Ваш билет на верную почту.`,
          expanded: false,
        },
        {
          title: 'Как вернуть/обменять билет?',
          description: 'Возможность вернуть или обменять билет зависит от тарифа по приобретенному Вами билету и правил авиакомпании. Чтобы узнать условия тарифа по Вашему билету и оформить возврат или обмен, нужно оставить заявку на нашем сайте через раздел <button class="faq-block__button-return">Заявка на возврат/обмен билета</button>.',
          expanded: false,
        },
        {
          title: 'Я допустил(а) ошибку в паспортных данных. Что делать?',
          description: `Для того чтобы у Вас не возникло проблем на регистрации в аэропорту вылета, все данные в билете должны быть указаны верно – как в документе. Если была допущена ошибка при указании ФИО, даты рождения или серии/номере документа, необходимо создать заявку на <button class="faq-block__button-exchange">Изменение паспортных данных</button>. Возможны штрафные санкции при переоформлении билета. Полная замена пассажира запрещена правилами авиакомпаний.`,
          expanded: false,
        },
        {
          title: 'Что делать если отменили рейс?',
          description: `Если Ваш рейс отменен или изменено расписание рейса, возможен вынужденный возврат билета без удержаний и штрафных санкций. Оформить возврат, либо отправить запрос на вынужденный обмен на другую удобную для вас дату на рейс этой же авиакомпании Вы можете через раздел <button class="faq-block__button-return">Заявка на возврат/обмен билета</button>. `,
          expanded: false,
        },
        {
          title: 'Оформлен билет на взрослого. Как добавить к нему ребенка?',
          description: `Пассажир старше 12 лет (включительно) считается взрослым и может самостоятельно совершать перелеты по РФ без сопровождения. Если ребенок младше 12 лет, оформить его отдельно от взрослого невозможно. Обратитесь к нам в Контактный центр. Мы будем рады помочь Вам оформить билет.`,
          expanded: false,
        },
      ],
    };
  },
  mounted() {
    document.querySelectorAll('.faq-block__button-return').forEach(item => {
      item.addEventListener('click', this.openReturn);
    });
    document.getElementsByClassName('faq-block__button-exchange')[0].addEventListener('click', this.openExchange);
  },
  methods: {
    expand(index) {
      this.list = this.list.map((item, i) => {
        return { ...item, expanded: index !== i ? false : !item.expanded };
      });
    },
    openReturn() {
      sendGlobalEvent('open-return');
    },
    openExchange() {
      sendGlobalEvent('open-exchange-pass');
    },
  },
};
